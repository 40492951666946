body {
    -ms-overflow-style: none;
}

.mat-nav-list a {
	text-decoration-line: underline !important; 
	text-decoration-color: #007846 !important;

	&:hover {
		background: unset !important;
	}
}