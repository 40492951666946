/* You can add global styles to this file, and also import other style files */
@import url('https://use.typekit.net/gpn4mdu.css');
@import '~@angular/material/theming';
@import 'themes/base';
@import 'themes/dark';


@import 'abstracts/variables';
@import 'abstracts/functions';
@import 'abstracts/mixins';
@import 'abstracts/placeholders';


@import 'base/reset';
@import 'base/typography';

@import 'layout/grid';


@import 'shame';


* {
	outline: 0 !important;
}

*:focus:not(:hover) {
    outline: solid blue rem(2px) !important;
}

// Place the upper X close button on dialogs
.close.mat-mini-fab {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    background: mat-color($primary, 700);
    border: rem(2px) solid white;
    line-height: rem(14px);
    min-width: auto;
    color: white;
    padding: 0 !important;

    .mat-button-wrapper {
        padding: 0;
    }
}

.cdk-overlay-pane.my-panel {
    position: relative !important;
	// width: unset !important;
	height: min-content; // Safari shows profile modal at full-screen height without this
}

// \Place the upper X close button on dialogs

// Make all dialogs look correct in Chrome, IE, Safari
.my-panel .mat-dialog-container {
    overflow: hidden; //ensure dialog has no scrollbars in IE11
    border-radius: rem(15px);
	padding: rem(26px);
}

// Make Orientation dialog have top graphic that goes right to edge
.my-panel.orientation-panel .mat-dialog-container {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
}

// Make video dialog have black bordering
.my-panel.video-panel .mat-dialog-container {
	background-color: black;
	padding: rem(19px) rem(12px) !important;
}

.my-panel.video-panel {
	width: auto !important;
}

@include xl() {
    body {
        margin: 0 calc((100% - #{$screen-xl-min}) / 2);
    }
}

.grecaptcha-badge { 
	visibility: hidden; 
}

.ie-scrollbar {
	-ms-overflow-style: scrollbar;
}

.material-icons {
	font-size: rem(24px);
}

button {
	font-size: rem($font-size-default) !important;
}