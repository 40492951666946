@import "abstracts/mixins";

.pace {
    -webkit-pointer-events: none;
    pointer-events: none;

    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;

    -webkit-border-radius: rem(10px);
    -moz-border-radius: rem(10px);
    border-radius: rem(10px);

    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;

    z-index: 2000;
    position: fixed;
    margin: auto;
    top: rem(12px);
    left: 0;
    right: 0;
    bottom: 0;
    width: rem(200px);
    height: rem(150px);
    overflow: hidden;
}

.pace .pace-progress {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;

    -webkit-border-radius: rem(2px);
    -moz-border-radius: rem(2px);
    border-radius: rem(2px);

    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;

    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);

    display: block;
    position: absolute;
    right: 100%;
    margin-right: rem(-7px);
    width: 93%;
    top: rem(7px);
    height: rem(14px);
    font-size: 0.75rem;
    background: #119942;
    color: white;
    line-height: rem(120px);
    font-weight: bold;
	font-family: Helvetica, Arial, "Lucida Grande", sans-serif;

    -webkit-box-shadow: rem(120px) 0 #fff, rem(240px) 0 #fff;
    -ms-box-shadow: rem(120px) 0 #fff, rem(240px) 0 #fff;
    box-shadow: rem(120px) 0 #fff, rem(240px) 0 #fff;
}

.pace .pace-progress:after {
    content: attr(data-progress-text);
    display: inline-block;
    position: relative;
    width: 100%;
    text-align: center;
    top: rem(4px);
    font-size: 1.75rem;
}

.pace .pace-progress[data-progress-text="0%"]:after {
    right: rem(-200px)
}

.pace .pace-progress[data-progress-text="1%"]:after {
    right: rem(-198.14px)
}

.pace .pace-progress[data-progress-text="2%"]:after {
    right: rem(-196.28px)
}

.pace .pace-progress[data-progress-text="3%"]:after {
    right: rem(-194.42px)
}

.pace .pace-progress[data-progress-text="4%"]:after {
    right: rem(-192.56px)
}

.pace .pace-progress[data-progress-text="5%"]:after {
    right: rem(-190.7px)
}

.pace .pace-progress[data-progress-text="6%"]:after {
    right: rem(-188.84px)
}

.pace .pace-progress[data-progress-text="7%"]:after {
    right: rem(-186.98px)
}

.pace .pace-progress[data-progress-text="8%"]:after {
    right: rem(-185.12px)
}

.pace .pace-progress[data-progress-text="9%"]:after {
    right: rem(-183.26px)
}

.pace .pace-progress[data-progress-text="10%"]:after {
    right: rem(-181.4px)
}

.pace .pace-progress[data-progress-text="11%"]:after {
    right: rem(-179.54px)
}

.pace .pace-progress[data-progress-text="12%"]:after {
    right: rem(-177.68px)
}

.pace .pace-progress[data-progress-text="13%"]:after {
    right: rem(-175.82px)
}

.pace .pace-progress[data-progress-text="14%"]:after {
    right: rem(-173.96px)
}

.pace .pace-progress[data-progress-text="15%"]:after {
    right: rem(-172.1px)
}

.pace .pace-progress[data-progress-text="16%"]:after {
    right: rem(-170.24px)
}

.pace .pace-progress[data-progress-text="17%"]:after {
    right: rem(-168.38px)
}

.pace .pace-progress[data-progress-text="18%"]:after {
    right: rem(-166.52px)
}

.pace .pace-progress[data-progress-text="19%"]:after {
    right: rem(-164.66px)
}

.pace .pace-progress[data-progress-text="20%"]:after {
    right: rem(-162.8px)
}

.pace .pace-progress[data-progress-text="21%"]:after {
    right: rem(-160.94px)
}

.pace .pace-progress[data-progress-text="22%"]:after {
    right: rem(-159.08px)
}

.pace .pace-progress[data-progress-text="23%"]:after {
    right: rem(-157.22px)
}

.pace .pace-progress[data-progress-text="24%"]:after {
    right: rem(-155.36px)
}

.pace .pace-progress[data-progress-text="25%"]:after {
    right: rem(-153.5px)
}

.pace .pace-progress[data-progress-text="26%"]:after {
    right: rem(-151.64px)
}

.pace .pace-progress[data-progress-text="27%"]:after {
    right: rem(-149.78px)
}

.pace .pace-progress[data-progress-text="28%"]:after {
    right: rem(-147.92px)
}

.pace .pace-progress[data-progress-text="29%"]:after {
    right: rem(-146.06px)
}

.pace .pace-progress[data-progress-text="30%"]:after {
    right: rem(-144.2px)
}

.pace .pace-progress[data-progress-text="31%"]:after {
    right: rem(-142.34px)
}

.pace .pace-progress[data-progress-text="32%"]:after {
    right: rem(-140.48px)
}

.pace .pace-progress[data-progress-text="33%"]:after {
    right: rem(-138.62px)
}

.pace .pace-progress[data-progress-text="34%"]:after {
    right: rem(-136.76px)
}

.pace .pace-progress[data-progress-text="35%"]:after {
    right: rem(-134.9px)
}

.pace .pace-progress[data-progress-text="36%"]:after {
    right: rem(-133.04px)
}

.pace .pace-progress[data-progress-text="37%"]:after {
    right: rem(-131.18px)
}

.pace .pace-progress[data-progress-text="38%"]:after {
    right: rem(-129.32px)
}

.pace .pace-progress[data-progress-text="39%"]:after {
    right: rem(-127.46px)
}

.pace .pace-progress[data-progress-text="40%"]:after {
    right: rem(-125.6px)
}

.pace .pace-progress[data-progress-text="41%"]:after {
    right: rem(-123.74px)
}

.pace .pace-progress[data-progress-text="42%"]:after {
    right: rem(-121.88px)
}

.pace .pace-progress[data-progress-text="43%"]:after {
    right: rem(-120.02px)
}

.pace .pace-progress[data-progress-text="44%"]:after {
    right: rem(-118.16px)
}

.pace .pace-progress[data-progress-text="45%"]:after {
    right: rem(-116.3px)
}

.pace .pace-progress[data-progress-text="46%"]:after {
    right: rem(-114.44px)
}

.pace .pace-progress[data-progress-text="47%"]:after {
    right: rem(-112.58px)
}

.pace .pace-progress[data-progress-text="48%"]:after {
    right: rem(-110.72px)
}

.pace .pace-progress[data-progress-text="49%"]:after {
    right: rem(-108.86px)
}

.pace .pace-progress[data-progress-text="50%"]:after {
    right: rem(-107px)
}

.pace .pace-progress[data-progress-text="51%"]:after {
    right: rem(-105.14px)
}

.pace .pace-progress[data-progress-text="52%"]:after {
    right: rem(-103.28px)
}

.pace .pace-progress[data-progress-text="53%"]:after {
    right: rem(-101.42px)
}

.pace .pace-progress[data-progress-text="54%"]:after {
    right: rem(-99.56px)
}

.pace .pace-progress[data-progress-text="55%"]:after {
    right: rem(-97.7px)
}

.pace .pace-progress[data-progress-text="56%"]:after {
    right: rem(-95.84px)
}

.pace .pace-progress[data-progress-text="57%"]:after {
    right: rem(-93.98px)
}

.pace .pace-progress[data-progress-text="58%"]:after {
    right: rem(-92.12px)
}

.pace .pace-progress[data-progress-text="59%"]:after {
    right: rem(-90.26px)
}

.pace .pace-progress[data-progress-text="60%"]:after {
    right: rem(-88.4px)
}

.pace .pace-progress[data-progress-text="61%"]:after {
    right: rem(-86.53999999999999px)
}

.pace .pace-progress[data-progress-text="62%"]:after {
    right: rem(-84.68px)
}

.pace .pace-progress[data-progress-text="63%"]:after {
    right: rem(-82.82px)
}

.pace .pace-progress[data-progress-text="64%"]:after {
    right: rem(-80.96000000000001px)
}

.pace .pace-progress[data-progress-text="65%"]:after {
    right: rem(-79.1px)
}

.pace .pace-progress[data-progress-text="66%"]:after {
    right: rem(-77.24px)
}

.pace .pace-progress[data-progress-text="67%"]:after {
    right: rem(-75.38px)
}

.pace .pace-progress[data-progress-text="68%"]:after {
    right: rem(-73.52px)
}

.pace .pace-progress[data-progress-text="69%"]:after {
    right: rem(-71.66px)
}

.pace .pace-progress[data-progress-text="70%"]:after {
    right: rem(-69.8px)
}

.pace .pace-progress[data-progress-text="71%"]:after {
    right: rem(-67.94px)
}

.pace .pace-progress[data-progress-text="72%"]:after {
    right: rem(-66.08px)
}

.pace .pace-progress[data-progress-text="73%"]:after {
    right: rem(-64.22px)
}

.pace .pace-progress[data-progress-text="74%"]:after {
    right: rem(-62.36px)
}

.pace .pace-progress[data-progress-text="75%"]:after {
    right: rem(-60.5px)
}

.pace .pace-progress[data-progress-text="76%"]:after {
    right: rem(-58.64px)
}

.pace .pace-progress[data-progress-text="77%"]:after {
    right: rem(-56.78px)
}

.pace .pace-progress[data-progress-text="78%"]:after {
    right: rem(-54.92px)
}

.pace .pace-progress[data-progress-text="79%"]:after {
    right: rem(-53.06px)
}

.pace .pace-progress[data-progress-text="80%"]:after {
    right: rem(-51.2px)
}

.pace .pace-progress[data-progress-text="81%"]:after {
    right: rem(-49.34px)
}

.pace .pace-progress[data-progress-text="82%"]:after {
    right: rem(-47.480000000000004px)
}

.pace .pace-progress[data-progress-text="83%"]:after {
    right: rem(-45.62px)
}

.pace .pace-progress[data-progress-text="84%"]:after {
    right: rem(-43.76px)
}

.pace .pace-progress[data-progress-text="85%"]:after {
    right: rem(-41.9px)
}

.pace .pace-progress[data-progress-text="86%"]:after {
    right: rem(-40.04px)
}

.pace .pace-progress[data-progress-text="87%"]:after {
    right: rem(-38.18px)
}

.pace .pace-progress[data-progress-text="88%"]:after {
    right: rem(-36.32px)
}

.pace .pace-progress[data-progress-text="89%"]:after {
    right: rem(-34.46px)
}

.pace .pace-progress[data-progress-text="90%"]:after {
    right: rem(-32.6px)
}

.pace .pace-progress[data-progress-text="91%"]:after {
    right: rem(-30.740000000000002px)
}

.pace .pace-progress[data-progress-text="92%"]:after {
    right: rem(-28.880000000000003px)
}

.pace .pace-progress[data-progress-text="93%"]:after {
    right: rem(-27.02px)
}

.pace .pace-progress[data-progress-text="94%"]:after {
    right: rem(-25.16px)
}

.pace .pace-progress[data-progress-text="95%"]:after {
    right: rem(-23.3px)
}

.pace .pace-progress[data-progress-text="96%"]:after {
    right: rem(-21.439999999999998px)
}

.pace .pace-progress[data-progress-text="97%"]:after {
    right: rem(-19.58px)
}

.pace .pace-progress[data-progress-text="98%"]:after {
    right: rem(-17.72px)
}

.pace .pace-progress[data-progress-text="99%"]:after {
    right: rem(-15.86px)
}

.pace .pace-progress[data-progress-text="100%"]:after {
    right: rem(-14px)
}


.pace .pace-activity {
    position: absolute;
    width: 100%;
    height: rem(28px);
    z-index: 2001;
}

.pace.pace-inactive {
    display: none;
}