@use "sass:map";

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$primary: mat-palette($mat-green);
$primary: map.merge($primary, (default: #108931));
$primary: map.merge($primary, (text: #108931));
$primary: map.merge($primary, ("button-color-contrast": #38a961));
$accent: mat-palette($mat-grey, A200, A100, A400);

// The warn palette is optional (defaults to red).
$warn: mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$light-theme: mat-light-theme($primary, $accent, $warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.

@include angular-material-theme($light-theme);
// Our dark theme
$dark-primary: mat-palette($mat-yellow);
$dark-primary: map.merge($dark-primary, ("button-color": #38a961));
$dark-primary: map.merge($dark-primary, ("button-color-contrast": #108931));

$dark-accent: mat-palette($mat-amber, A400, A100, A700);
$dark-warn: mat-palette($mat-red);
$dark-theme: mat-dark-theme($dark-primary, $dark-accent, $dark-warn);

.app-dark-theme {
    color: $light-primary-text;

	@include angular-material-color($dark-theme);
	
	.mat-card {
		background-color: #2e2e2e !important;
		color: white !important;
	}
}

.app-tabs {
	$tabs: map.merge($primary, ("default": #f0f0f0));
	$tab-theme: mat-light-theme($tabs, $accent, $warn);
	 
	 @include angular-material-theme($tab-theme);
}

.mat-flat-button {
	color: white !important;
	white-space: pre-wrap !important;
	background-color: #108931 !important;
}

.mat-stroked-button {
	border-color: mat-color($primary, "default") !important;
}

a:hover {
	cursor: pointer;
}

span.highlightText {
	background-color: yellow;

	.app-dark-theme & {
		background-color: #108931;
	}
}
