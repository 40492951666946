$screen-xs-min: 320px;  // Tiny phones
$screen-sm-min: 375px;  // Small tablets and large smartphones (landscape view)
$screen-smd-min: 690px;  // Small-medium tablets (portrait view)
$screen-md-min: 768px;  // Medium tablets (portrait view)
$screen-lg-min: 1280px;  // Tablets and small desktops
$screen-xl-min: 1920px; // Large tablets and desktops

$font-family-default: acumin-pro;
$font-size-default: 18px;
$font-weight-default: 500;
$font-stretch-default: normal;
$font-style-default: normal;
$line-height-default: 1.44;
$letter-spacing-default: normal;

$font-weight-normal: normal;
$font-weight-bold: 600;

$text-color-light: #007846;
$text-color-dark: white;