@import "abstracts/variables";

@mixin xs { @media (min-width: #{$screen-xs-min}) {@content;} } // Tiny devices
@mixin sm { @media (min-width: #{$screen-sm-min}) {@content;} } // Small devices
@mixin smd { @media (min-width: #{$screen-smd-min}) {@content;} } // Small devices
@mixin md { @media (min-width: #{$screen-md-min}) {@content;} } // Medium devices
@mixin lg { @media (min-width: #{$screen-lg-min}) {@content;} } // Large devices
@mixin xl { @media (min-width: #{$screen-xl-min}) {@content;} } // Extra large dev

@function rem($pixels, $sub: 0, $add: 0) {

    @if (unitless($pixels)) {
      $pixels: $pixels * 1px;
    }

	@if (unitless($sub)) {
		$sub: $sub * 1px;
	}

	@if (unitless($add)) {
		$add: $add * 1px;
	}
  
	@return (($pixels - $sub + $add) / $font-size-default) * 1rem; 
}