@import "abstracts/variables";

body {
	font-stretch: $font-stretch-default;
	font-style: $font-style-default;
	letter-spacing: $letter-spacing-default;

	-webkit-text-size-adjust: 100%; //fix for iOS
}

// Define a custom typography config that overrides the font-family as well as the
// `headlines` and `body-1` levels.
$custom-typography: mat-typography-config(
  $caption: mat-typography-level($font-size-default),
  $font-family: $font-family-default,
  $headline: mat-typography-level(32px, 48px, 700),
  $body-1: mat-typography-level($font-size-default, $line-height-default, 500),
  $body-2: mat-typography-level($font-size-default, $line-height-default, $font-weight-bold),
  $button: mat-typography-level($font-size-default, $line-height-default, 500),
  $subheading-1: mat-typography-level($font-size-default, $line-height-default, 500),
  $subheading-2: mat-typography-level($font-size-default, $line-height-default, 500)
);

// body-2 applies family, size, and weight to any element with class mat-subheader 
// Override typography for all Angular Material, including mat-base-typography and all components.
// @include angular-material-typography($custom-typography);

// Override the typography in the core CSS.
@include mat-core($custom-typography);
// @include mat-expansion-panel-typography($custom-typography);
//@include mat-list-typography($custom-list-typography);

html {
	font-size: $font-size-default !important;
}

 body {
	font-family: $font-family-default;
 }